@font-face {
  font-family: "Gotham Pro";
  src: url("../../../../fonts/gotham-pro/gothampro-light-webfont.woff2")
      format("woff2"),
    url("../../../../fonts/gotham-pro/gothampro-light-webfont.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../../../fonts/gotham-pro/gothampro-webfont.woff2")
      format("woff2"),
    url("../../../../fonts/gotham-pro/gothampro-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../../../fonts/gotham-pro/gothampro-medium-webfont.woff2")
      format("woff2"),
    url("../../../../fonts/gotham-pro/gothampro-medium-webfont.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../../../fonts/gotham-pro/gothampro-bold-webfont.woff2")
      format("woff2"),
    url("../../../../fonts/gotham-pro/gothampro-bold-webfont.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../../../../fonts/gotham-pro/gothampro-black-webfont.woff2")
      format("woff2"),
    url("../../../../fonts/gotham-pro/gothampro-black-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}
